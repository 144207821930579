import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import PaymentTable from 'components/section-components/table-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Mortgage() {
  const [amount, setAmount] = useState(100000);
  const [interest, setInterest] = useState(0);
  const [years, setYears] = useState('0');
  const [isYear] = useState(false);
  const [annuity, setAnnuity] = useState(0);
  const [tableData, setData] = useState([]);
  const [val, setVal] = useState(10);
  const [onFocuss1, setFocus1] = useState(false);
  const [currency, serCurrency] = useState(1);
  const [warning, setWarning] = useState(false);
  const emailRegex = /^[0-9\b]+$/;
  const { t } = useTranslation();
  const windowWidth = 500;
  const data = {
    mainNavbar: ' main-navbar-area',
    searchBar: 'hidden-for-scroll',
  };
  function calculateAnnuity(years, interest, amount) {
    const n = isYear ? years * 12 : years;

    if (interest === 0) {
      return amount / n;
    }

    const i = interest / 100 / 12;
    let pow = i + 1;
    pow = Math.pow(pow, -n);
    return (i / (1 - pow)) * amount;
  }

  function calculateAnnuityHandler() {
    let date = years === '' ? '' : +years;
    if (date && (isYear ? date < 31 : date < 361 && emailRegex.test(date))) {
      const newAmount = amount - (amount * val) / 100;
      const annuityCalculated = calculateAnnuity(date, interest, newAmount);
      if (annuityCalculated) {
        setAnnuity(Math.round(annuityCalculated));
        setWarning(false);
      }
    } else {
      setWarning(true);
    }
  }

  function formatAmount(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    function Months() {
      let tableList = [];
      let newAmount = amount - (amount * val) / 100;
      let year = isYear ? years * 12 : years;
      for (let i = 0; i < year; i++) {
        let tabloItem = {};
        let monthly_fee = (newAmount * interest) / 100 / 12;
        let mother = annuity - monthly_fee;
        newAmount = newAmount - mother;
        tabloItem.index = i + 1;
        tabloItem.fee = Math.floor(monthly_fee);
        tabloItem.mother = Math.floor(mother);
        tabloItem.annuity = annuity;
        tableList.push(tabloItem);
      }

      setData(tableList);
      newAmount = 0;
    }
    let date = years === '' ? '' : +years;
    if (annuity > 0 && date && amount && emailRegex.test(date)) {
      Months();
    }
  }, [annuity, amount]);

  function currencies() {
    switch (currency) {
      case 1:
        return '֏';
      case 2:
        return '$';
      case 3:
        return '₽';
      default:
    }
  }

  function maxes() {
    switch (currency) {
      default:
      case 1:
        return 100000;
      case 2:
        return 100;
      case 3:
        return 100;
    }
  }

  function byDefault(amount) {
    setAmount(amount);
    setInterest(0);
    setYears('0');
    setAnnuity(0);
    setVal(10);
    setData([]);
  }
  function myCurrencies(x, y) {
    serCurrency(x);
    byDefault(y);
  }

  function myBottonStyle(x) {
    const buttonStyle = {
      backgroundColor: currency === x ? '#A12531' : 'white',
      borderRadius: 25,
      width: 50,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      color: currency === x ? 'white' : 'black',
    };
    return buttonStyle;
  }

  return (
    <>
      <Navbar t={t} data={data} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          alignItems: 'center',
          paddingTop: 10,
          padding: '90px 0 15px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '50%',
            marginTop: 10,
          }}
        >
          <button
            onClick={() => {
              myCurrencies(1, 100000);
            }}
            style={myBottonStyle(1)}
          >
            ֏
          </button>
          <button
            style={myBottonStyle(2)}
            onClick={() => {
              myCurrencies(2, 100);
            }}
          >
            $
          </button>
          <button
            onClick={() => {
              myCurrencies(3, 100);
            }}
            style={myBottonStyle(3)}
          >
            ₽
          </button>
        </div>
        {warning ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <div style={{ color: '#A12531' }}>{t('mg_warning')}</div>
          </div>
        ) : null}
        <div className="px-3 mt-3">
          <div className="form-group d-flex justify-content-between mt-3 align-items-center">
            <h5>{!isYear ? t('month') : t('year')}</h5>
            <input
              value={years}
              onChange={(e) => setYears(e.target.value)}
              onFocus={() => setFocus1(true)}
              onBlur={() => setFocus1(false)}
              placeholder={!isYear ? t('month') : t('year')}
              maxLength={3}
              style={{
                backgroundColor: '#E4E4E4',
                width: '45%',
                height: 35,
                borderRadius: 10,
                marginBottom: 10,
                marginLeft: 30,
                fontSize: 18,
                paddingLeft: 10,
                color: 'gray',
                borderWidth: onFocuss1 ? 1 : 0,
                borderColor: 'red',
                flex: 1,
              }}
            />
          </div>
          <div className="form-group">
            <h5>
              {t('persent')} ({interest}%)
            </h5>
            <input
              type="range"
              value={interest}
              onChange={(value) => {
                setInterest(Number(value.target.value));
              }}
              max={100}
              min={0}
              step={0.5}
            />
          </div>
          <div className="form-group">
            <h5>
              {t('loan_amount')} ({formatAmount(amount)}
              {currencies()})
            </h5>
            {currency === 1 ? (
              <input
                type="range"
                value={amount}
                onChange={(value) => {
                  setAmount(value.target.value);
                }}
                max={500000000}
                min={maxes()}
                step={maxes()}
              />
            ) : null}
            {currency === 2 ? (
              <input
                type="range"
                value={amount}
                onChange={(value) => {
                  setAmount(value.target.value);
                }}
                max={100000}
                min={100}
                step={100}
              />
            ) : null}
            {currency === 3 ? (
              <input
                type="range"
                value={amount}
                onChange={(value) => {
                  setAmount(value.target.value);
                }}
                max={100000}
                min={100}
                step={100}
              />
            ) : null}
          </div>
          <div className="form-group">
            <h5>
              {t('loan_prepayment')} {val + `%`}
            </h5>
            <input
              type="range"
              value={val}
              onChange={(value) => {
                setVal(value.target.value);
              }}
              max={90}
              min={10}
              step={10}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '90%',
          }}
        >
          <button
            onClick={() => {
              calculateAnnuityHandler();
            }}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              height: 50,
              backgroundColor: '#A12531',
              borderRadius: 10,
              marginBottom: 10,
              color: 'white',
              fontSize: 18,
              border: 'none',
              cursor: 'pointer',
            }}
            className="px-4"
          >
            {t('calculate')}
          </button>
        </div>

        <>
          {tableData.length > 0 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: 'silver',
                    width: '90%',
                    marginBottom: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                      width: windowWidth - windowWidth * 0.1,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 20,
                        marginBottom: 20,
                      }}
                    >
                      {t('payment')} ({currencies()})
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                            paddingLeft: 5,
                          }}
                        >{`${t('monthly_fee')} (${currencies()}) : `}</div>
                        <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(annuity)}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                            paddingLeft: 5,
                          }}
                        >{`${t('term')}(${!isYear ? t('month') : t('year')}) : `}</div>
                        <div style={{ fontSize: 18, color: '#A12531' }}>{years}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                            paddingLeft: 5,
                          }}
                        >{`${t('annual_interest_rate')} (%) : `}</div>
                        <div style={{ fontSize: 18, color: '#A12531' }}>{interest}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                            paddingLeft: 5,
                          }}
                        >{`${t('appartment_price')} (${currencies()}) : `}</div>
                        <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(amount)}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                            paddingLeft: 5,
                          }}
                        >{`${t('loan_prepayment')} (${currencies()}): `}</div>
                        <div
                          style={{
                            fontSize: 18,
                            color: '#A12531',
                          }}
                        >
                          {formatAmount((amount * val) / 100)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: '80%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderTopWidth: 1,
                      paddingLeft: 10,
                      width: '100%',
                      alignItems: 'center',
                      border: '1px solid white',
                      backgroundColor: '#A12531',
                      borderRadius: '10px',
                    }}
                  >
                    <div style={{ width: '25%' }}>
                      <div
                        style={{
                          fontSize: 18,
                          color: 'white',
                        }}
                      >
                        {t('month')}
                      </div>
                    </div>
                    <div style={{ width: '25%' }}>
                      <div
                        style={{
                          fontSize: 18,
                          color: 'white',
                        }}
                      >
                        {t('fee')}
                      </div>
                    </div>
                    <div style={{ width: '25%' }}>
                      <div
                        style={{
                          fontSize: 18,
                          color: 'white',
                        }}
                      >
                        {t('mother')}
                      </div>
                    </div>
                    <div style={{ width: '25%' }}>
                      <div style={{ fontSize: 18, color: 'white' }}>{t('annuity')}</div>
                    </div>
                  </div>
                  <PaymentTable tableData={tableData} currency={currency} />
                </div>
              </div>
            </>
          ) : null}
        </>
      </div>
      <Footer t={t} />
    </>
  );
}

export default Mortgage;
