import Errorpage from 'components/section-components/error';
import React from 'react';

const Error = () => {
  return (
    <div>
      <Errorpage />
    </div>
  );
};

export default Error;
