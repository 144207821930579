import axios from 'axios';
import { getAllBanners } from 'reducers/categoryReducer';

export const getBanners = () => async (dispatch) => {
  try {
    const response = await axios.post('api/banners', {});
    dispatch(getAllBanners(response.data));
  } catch (e) {
    console.log(e, 'eeeeeeerespons');
  }
};
