import { memo } from 'react';

function PaymentTable({ tableData, currency }) {
  function currencies() {
    switch (currency) {
      case 1:
        return '֏';
      case 2:
        return '$';
      case 3:
        return '₽';
      default:
    }
  }
  function formatAmount(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return tableData.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: 10,
          borderTop: '1px solid #A12531',
          width: '100%',
        }}
      >
        <div style={{ paddingVertical: 5, width: '25%' }}>
          <div style={{ fontSize: 15 }}>{item.index}</div>
        </div>
        <div style={{ paddingVertical: 5, width: '25%' }}>
          <div style={{ fontSize: 15 }}>
            {formatAmount(item.fee)}({currencies()})
          </div>
        </div>
        <div style={{ paddingVertical: 5, width: '25%' }}>
          <div style={{ fontSize: 15 }}>
            {formatAmount(item.mother)}({currencies()})
          </div>
        </div>
        <div style={{ paddingVertical: 5, width: '25%' }}>
          <div style={{ fontSize: 15 }}>
            {formatAmount(item.annuity)}({currencies()})
          </div>
        </div>
      </div>
    );
  });
}

export default memo(PaymentTable);
