import axios from 'axios';
import clsx from 'clsx';
import Pagination from 'components/section-components/pagination';
import { baseStorageUrl } from 'configs/env';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Table, UncontrolledTooltip } from 'reactstrap';

const bookingStatuses = {
  pending: 'pending',
  confirmed: 'confirmed',
  rejected: 'rejected',
  completed: 'completed',
};

const MyBookings = ({ t }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [res, setRes] = useState(null);
  const [isPendingAction, setIsPendingAction] = useState(false);

  const getMyBookings = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await axios.get('api/bookings', {
        params: { page },
      });
      if (response.status === 200) {
        setRes(response?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelMyBooking = async (id) => {
    // 400: Internal error
    // 404: Booking record not found
    try {
      setIsPendingAction(true);
      const response = await axios.delete(`api/booking/${id}`);
      if (response.status === 200) {
        setIsLoading(true);
        await getMyBookings(res?.current_page);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setIsPendingAction(false);
    }
  };

  useEffect(() => {
    getMyBookings();
  }, []);

  return (
    <div className="my-bookings-page-wrapper favorites-page-wrap">
      {isLoading ? (
        <div className="spinner_content">
          <div className="sweet-loading">
            <PulseLoader color={'#BE1E2D'} loading={true} size={16} />
          </div>
        </div>
      ) : (
        <>
          <Table>
            <thead>
              <tr className="broker-tab">
                <th></th>
                <th>{t('price')}</th>
                <th>{t('start_date')}</th>
                <th>{t('end_date')}</th>
                <th>{t('status')}</th>
                <th className="actionButtons" />
              </tr>
            </thead>
            <tbody style={{ padding: '2%' }}>
              {res?.data?.length > 0 &&
                res.data.map((item) => {
                  const listing = item.listing;

                  return (
                    <tr key={item.id}>
                      <td>
                        <img
                          onClick={() => {
                            history.push(`/property-details/${item.listing_id}`);
                          }}
                          className="booking-img"
                          src={baseStorageUrl + '/uploads/announcements/' + listing.main_image}
                          alt="listing"
                        />{' '}
                      </td>
                      <td>
                        {listing.currency && listing.currency.name} {listing.price}
                      </td>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>
                        <span
                          className={clsx({
                            verifyStatusPending: item.status === bookingStatuses.pending,
                            verifyStatusActive: item.status === bookingStatuses.confirmed,
                            verifyStatusRejected: item.status === bookingStatuses.rejected,
                            verifyStatusCompleted: item.status === bookingStatuses.completed,
                          })}
                        >
                          {t(bookingStatuses[item.status])}
                        </span>
                      </td>
                      <td>
                        {item.status === bookingStatuses.pending && (
                          <>
                            <button
                              id={`cancel-${item.id}`}
                              type="submit"
                              className="bookingTableBtn"
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className="property-filter-menu buttons">
                                        <h1>{t('are_you_sure')}</h1>
                                        <p>{t('cancel_booking_confirmation')}</p>
                                        <button
                                          disabled={isPendingAction}
                                          className="active"
                                          onClick={async () => {
                                            await cancelMyBooking(item.id);
                                            onClose();
                                          }}
                                        >
                                          {t('yes')}
                                        </button>
                                        <button disabled={isPendingAction} className="active" onClick={onClose}>
                                          {t('no')}
                                        </button>
                                      </div>
                                    );
                                  },
                                });
                              }}
                            >
                              <i className="cancelIcon fa fa-ban fa-lg" aria-hidden="true" />
                            </button>
                            <UncontrolledTooltip placement="top" target={`cancel-${item.id}`}>
                              {t('cancel')}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {res?.last_page && res.last_page > 1 ? (
            <Pagination
              withoutPaddingTop
              total={res?.total}
              getPage={getMyBookings}
              forcePage={res?.current_page ? res?.current_page - 1 : 0}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default MyBookings;
