import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import FavoritesGrid from 'components/section-components/favorites-grid';
import SearchBar from 'components/section-components/search-bar/search-bar';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Favorites = () => {
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    mainNavbar: ' ',
  };
  const { t } = useTranslation();

  return (
    <div>
      <Navbar data={data} t={t} />
      <SearchBar t={t} />
      <FavoritesGrid t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Favorites;
