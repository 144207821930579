import { GoogleMap, InfoWindow, MarkerClusterer, Polygon, useLoadScript } from '@react-google-maps/api';
import CustomMarker from 'components/custom-marker/custom-marker';
import { baseStorageUrl } from 'configs/env';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

function MapContainer({ zoom, currentCoordinates, allCoordinates, poligonCoordinates, priceFormat, currencyFormat }) {
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const history = useHistory();
  const publicUrl = process.env.PUBLIC_URL;
  const default_image = publicUrl + '/assets/img/default.png';

  // useEffect(() => {
  //         setCenter(state)
  // }, [])

  function polygons() {
    let newPolygonsContainer = [];
    poligonCoordinates[0].forEach((item) => {
      const myPolygons = {};
      myPolygons.lat = item[0];
      myPolygons.lng = item[1];
      newPolygonsContainer.push(myPolygons);
    });
    return newPolygonsContainer;
  }
  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    allCoordinates.map((place) => {
      bounds.extend({ lat: +place.latitude, lng: +place.longitude });
      return place.id;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    setMapRef(map);
    // fitBounds(map);
  };

  const useFoo = () => {
    return useMemo(() => {
      const state = {
        lat: currentCoordinates[0],
        lng: currentCoordinates[1],
      };
      return state;
    }, []);
  };
  const foo = useFoo();

  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    setSelectedPlace(place);
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };

  const linkTo = (e, id) => {
    e.stopPropagation();
    if (e.target.className !== 'fa fa-heart-o cursor' && e.target.className !== 'fa fa-heart activeHeart cursor') {
      history.push(`/property-details/${id}`);
    }
  };
  const options = {
    fillColor: '#BE1E2D',
    fillOpacity: 0.1,
    strokeColor: '#BE1E2D',
    strokeOpacity: 0.9,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const renderMap = () => {
    return (
      <>
        <GoogleMap
          onLoad={loadHandler}
          onClick={(e) => setClickedLatLng(e.latLng.toJSON())}
          center={foo}
          zoom={zoom}
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
        >
          <MarkerClusterer averageCenter={true} clusterClass={'cluster'}>
            {(clusterer) =>
              allCoordinates.map((place) => (
                <CustomMarker
                  number={priceFormat(place) + ' ' + currencyFormat(place)}
                  key={place.id}
                  clusterer={clusterer}
                  position={{ lat: +place.latitude, lng: +place.longitude }}
                  onLoad={(marker) => markerLoadHandler(marker, place)}
                  onClick={(event) => markerClickHandler(event, place)}
                />
              ))
            }
          </MarkerClusterer>
          {infoOpen && selectedPlace && (
            <InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setInfoOpen(false)} zIndex={9999}>
              <div>
                <img
                  src={
                    selectedPlace.main_image
                      ? baseStorageUrl + '/uploads/announcements/' + selectedPlace.main_image
                      : default_image
                  }
                  alt={selectedPlace.property_name}
                  width={'100%'}
                  height={300}
                  onClick={(e) => {
                    linkTo(e, selectedPlace.id);
                  }}
                />
                <div>{selectedPlace.address}</div>
                <div>{priceFormat(selectedPlace) + ' ' + currencyFormat(selectedPlace)}</div>
              </div>
            </InfoWindow>
          )}
          <Polygon path={polygons()} options={options} />
        </GoogleMap>
      </>
    );
  };

  return isLoaded ? renderMap() : null;
}

export default MapContainer;
