import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory, useParams } from 'react-router-dom';

const ConstructionPagination = (props) => {
  let data2 = props.data2;
  const getPage = props.getPage;
  const count = props.count ? props.count : 6;
  const style = props.styles ? '' : 'pd-top-50';
  const history = useHistory();
  const { current_page } = useParams();
  const [localCurrentPage, setLocalCurrentPage] = useState(Number(current_page) || 1);

  const handlePageClick = ({ selected: selectedPage }) => {
    setLocalCurrentPage(selectedPage + 1);
    getPage(selectedPage + 1);
    history.push(`/from-construction/${selectedPage + 1}`);
  };

  return (
    <div className={`pagination-content ${style}`}>
      <ReactPaginate
        pageCount={data2.total / count}
        disableInitialCallback={true}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        previousLabel={'<'}
        nextLabel={'>'}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        previousLinkClassName={'pagination_link'}
        nextLinkClassName={'pagination_link'}
        disabledClassName={'pagination_link--disabled'}
        activeClassName={'pagination_link_active'}
        forcePage={localCurrentPage - 1}
      />
    </div>
  );
};

export default ConstructionPagination;
