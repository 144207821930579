export const PRICE_TYPES = {
  start: 'start',
  end: 'end',
};
export const LANGUAGES_LOCAL_CODES = {
  am: 'am',
  en: 'en',
  ru: 'ru',
};
export const AIR_POLUTION_TYPES = {
  1: 'Good',
  2: 'Good',
  3: 'Good',
  4: 'Good',
};
export const FAVORITE_ENTITY_TYPES = {
  construction: 'construction',
  listing: 'listing',
};
