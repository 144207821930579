import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { baseStorageUrl } from 'configs/env';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';

export const Banner = () => {
  const banner = useSelector((state) => state.category.banners);

  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      stopOnHover={true}
      showThumbs={false}
      showIndicators={false}
    >
      <div>
        {banner[1] ? <img alt="" src={`${baseStorageUrl}/${banner[3].main_image}`} /> : <div>there are no banner</div>}
      </div>
      <div>
        {banner[1] ? <img alt="" src={`${baseStorageUrl}/${banner[4].main_image}`} /> : <div>there are no banner</div>}
      </div>
      <div>
        {banner[1] ? <img alt="" src={`${baseStorageUrl}/${banner[5].main_image}`} /> : <div>there are no banner</div>}
      </div>
    </Carousel>
  );
};
