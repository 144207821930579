const GET_CATEGORY = 'GET_CATEGORY';

const defaultState = {
  categories: [],
  banners: [],
};

export default function typeReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case 'GET_BANNERS':
      return {
        ...state,
        banners: action.payload,
      };
    default:
      return state;
  }
}

export const getCategories = (categories) => ({ type: GET_CATEGORY, payload: categories });
export const getAllBanners = (banners) => ({ type: 'GET_BANNERS', payload: banners });
