import 'components/translations/i18n';

import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import Agencies from 'components/section-components/agencies';
import Agents from 'components/section-components/agents';
import { Banner } from 'components/section-components/banner';
import Service from 'components/section-components/service';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Home = () => {
  window.scrollTo(0, 0);
  const data = {
    mainNavbar: ' main-navbar-area',
    searchBar: 'hidden-for-scroll',
  };
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('meta_home_title1')}</title>
        <meta name="description" content={t('meta_home_description')} />
        <meta name="keywords" content="1SQ, 1SQ Realty, Real Estate Yerevan, 1SQ Realty Yerevan" />
        <meta name="google-site-verification" content="na6RGhF-BFX1YJnDy1ueptCb2GqwO1AvLkj4dCahNDQ" />
      </Helmet>
      <Navbar t={t} data={data} />
      <Banner t={t} />
      <Service t={t} />
      <Agents t={t} />
      <Agencies t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Home;
