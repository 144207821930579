import clsx from 'clsx';
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ total, getPage, setPage, count = 6, withoutPaddingTop = false, forcePage }) => {
  const handlePageClick = ({ selected: selectedPage }) => {
    getPage(++selectedPage);
    setPage?.(selectedPage);
  };

  return (
    <div className={clsx('pagination-content', { 'pd-top-50': !withoutPaddingTop })}>
      <ReactPaginate
        pageCount={total / count}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        previousLabel={'<'}
        nextLabel={'>'}
        forcePage={forcePage}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        previousLinkClassName={'pagination_link'}
        nextLinkClassName={'pagination_link'}
        disabledClassName={'pagination_link--disabled'}
        activeClassName={'pagination_link_active'}
      />
    </div>
  );
};

export default Pagination;
