import { css } from '@emotion/react';
import { currencies } from 'actions/announcement';
import { all_constructions, constructions } from 'actions/construction';
import Map from 'components/section-components/construction-map';
import ConstructionPagination from 'components/section-components/construction-pagination';
import { baseStorageUrl } from 'configs/env';
import { FAVORITE_ENTITY_TYPES, LANGUAGES_LOCAL_CODES } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { setModal, setSpinner } from 'reducers/modalsReducer';
import { addToFavorites, getTranslationByLocale } from 'utils';

import polygons from '../../data/coordinates.json';

const ConstructionList = (props) => {
  const banner = useSelector((state) => state.category.banners);

  const publicUrl = process.env.PUBLIC_URL;
  const default_image = publicUrl + '/assets/img/default.png';
  const { t } = props;
  const dispatch = useDispatch();
  const [poligonCoordinates, setPoligonCoordinates] = useState([]);
  const allConstructors = useSelector((state) => state.construction.allConstructions);
  const spinner = useSelector((state) => state.modals.spinner);
  const searchParams = useSelector((state) => state.construction.searchParams);
  const { current_page } = useParams();

  const isLogin = () => {
    dispatch(setModal('login'));
  };

  useEffect(() => {
    async function response() {
      setPoligonCoordinates(polygons.coordinates[searchParams.region || '1']);
    }

    dispatch(all_constructions(searchParams.region));
    response();
  }, [dispatch, searchParams.region]);
  useEffect(() => {
    dispatch(currencies());
    dispatch(constructions([], [null, null], Number(current_page)));
  }, [dispatch]);
  let allConstructions = useSelector((state) => state.construction.constructions);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const openMapIcon = publicUrl + '/assets/img/icons/open-map.png';
  const openListIcon = publicUrl + '/assets/img/icons/open-list.png';
  const [mapListToggle, setMapListToggle] = useState('false');

  const getPageConstructors = (page) => {
    dispatch(setSpinner(true));
    dispatch(constructions(searchParams, [null, null], page));
  };

  return (
    <>
      <div style={{ margin: '0 auto', width: '100%' }}>
        {banner[1] && <img alt="" src={`${baseStorageUrl}/${banner[2].main_image}`} width="100%" />}
      </div>
      <div className="pd-top-20" style={{ backgroundColor: '#FBFBFB' }}>
        <div className="search-container construction-container-desktop">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 scrollCss">
                <div>
                  {allConstructions.last_page && allConstructions.last_page > 1 ? (
                    <ConstructionPagination
                      data2={allConstructions}
                      getPage={getPageConstructors}
                      count={3}
                      styles={'padding'}
                    />
                  ) : (
                    ''
                  )}
                  {spinner ? (
                    <div className="spinner_content">
                      <div className="sweet-loading">
                        <PulseLoader color={'#BE1E2D'} css={override} loading={true} size={16} />
                      </div>
                    </div>
                  ) : allConstructions.data ? (
                    allConstructions.data.map((item, i) => (
                      <Link to={'/construction-details/' + item.id} key={i}>
                        <div key={i} className="single-feature style-two construction">
                          <div className="thumb">
                            <LazyLoadImage
                              alt={item.property_name && item.property_name}
                              height="220px"
                              src={
                                item.main_image
                                  ? baseStorageUrl + '/uploads/constructors/' + item.main_image
                                  : default_image
                              }
                            />
                          </div>
                          {item.const_agency ? (
                            <div className="single-feature-details">
                              <div className="constructor-feature-logo">
                                <LazyLoadImage
                                  alt={item.const_agency && item.const_agency.first_name}
                                  src={
                                    item.const_agency && item.const_agency.avatar
                                      ? baseStorageUrl + '/uploads/users/' + item.const_agency.avatar
                                      : default_image
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="construction-wrap ">
                            <div>
                              <div className="flex-row-align-start">
                                <span className="constructionTitle flex1">
                                  {getTranslationByLocale(item.translations, 'property_name')}
                                </span>
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    (localStorage.token || isLogin()) &&
                                      addToFavorites(e, item.id, FAVORITE_ENTITY_TYPES.construction);
                                  }}
                                  className={'heart'}
                                >
                                  <i
                                    className={
                                      item?.isFavorite ? 'fa fa-heart activeHeart cursor' : 'fa fa-heart-o cursor'
                                    }
                                  />
                                </span>
                              </div>
                              <br />
                              <span className="constructionSubTitle">
                                {item.translations.map((value) => {
                                  return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                    value.locale === localStorage.i18nextLng
                                    ? value.sub_title
                                    : null;
                                })}
                              </span>
                              <br />
                              {!item.sold ? (
                                <span className="constructionPrice">
                                  <NumberFormat
                                    value={item.price_start}
                                    displayType={'text'}
                                    prefix={
                                      item.currency && item.currency.local === LANGUAGES_LOCAL_CODES.am
                                        ? item.currency.name + ' '
                                        : ' '
                                    }
                                    thousandSeparator={true}
                                  />
                                  <NumberFormat
                                    value={item.price_end}
                                    prefix={
                                      item.currency &&
                                      (item.currency.local === LANGUAGES_LOCAL_CODES.am
                                        ? ' - ' + ' ' + item.currency.name + ' '
                                        : '')
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                </span>
                              ) : (
                                <span className="constructionPrice">{t('sold')}</span>
                              )}
                            </div>
                            <p>
                              <i className="fa fa-map-marker" />{' '}
                              {item.translations.map((value) => {
                                return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                  value.locale === localStorage.i18nextLng
                                  ? value.address
                                  : null;
                              })}
                            </p>
                            {item.open_to_visit && item.close_to_visit ? (
                              <p>
                                <i className="fa fa-clock-o" aria-hidden="true">
                                  {' '}
                                </i>
                                {item.open_to_visit.split(' ')[1].slice(0, 5)} :{' '}
                                {item.close_to_visit.split(' ')[1].slice(0, 5)}
                              </p>
                            ) : null}
                            <p>
                              <i className="fa fa-calendar-o" aria-hidden="true">
                                {' '}
                                {item.start_date} : {item.end_date}
                              </i>
                            </p>
                            <p>
                              <img alt={'keyLogo'} src={publicUrl + '/assets/img/icons/key.png'} />{' '}
                              {t('available2') + ' ' + item.available_apartments + '/' + item.apartment_counts}
                            </p>
                            <p>
                              <img alt={'areaLogo'} src={publicUrl + '/assets/img/icons/measured.png'} /> {item.area}{' '}
                              {t('m')}²
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    t('no_result')
                  )}
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7" style={{ height: '925px' }}>
                {/* <YMaps query={{apikey: process.env.REACT_APP_Y_API_KEY}}>
                            <Map width="100%" height="100%" defaultState={{center: [40.135282, 44.512432], zoom: 12}}>
                                {allConstructors.length ? allConstructors.map((construct, i) =>
                                    <Placemark key={i}
                                               geometry={[construct.latitude, construct.longitude]}
                                               options={mapOptions}
                                               draggable={true}
                                               properties={{
                                                   balloonContent: `<a href=${'/construction-details/' + construct.id}><img src=${baseStorageUrl + '/uploads/constructors/' + construct.main_image}><strong>${construct.currency && construct.currency.name}${construct.price_start} ${construct.price_end ? ' - ' + (construct.currency && ' ' + construct.currency.name) + construct.price_end : ''}</br> ${construct.address} </strong></a>`,
                                                   iconCaption: `${construct.currency && ' ' + construct.currency.name}${construct.price_start} ${construct.price_end ? ' - ' + (construct.currency && ' ' + construct.currency.name) + construct.price_end : ''}`,
                                                   open: true,
                                               }}
                                               modules={['geoObject.addon.balloon']}/>) : ""}

                                <Polygon
                                    geometry={poligonCoordinates}
                                    options={{
                                        fillColor: '#BE1E2D',
                                        fillOpacity: 0.04,
                                        strokeColor: '#BE1E2D',
                                        strokeOpacity: 0.9,
                                        strokeWidth: 2,
                                    }}
                                />
                            </Map>
                        </YMaps> */}

                <Map allConstructors={allConstructors} poligonCoordinates={poligonCoordinates} />
              </div>
            </div>
          </div>
        </div>
        <div className={'construction-list-map'}>
          <div className={'construction-container-top'}>
            <div
              className={'map-list-toggle-container ml-3 mb-3'}
              onClick={() => {
                setMapListToggle((v) => !v);
              }}
            >
              {mapListToggle ? (
                <div className={'map-list-toggle'}>
                  <img src={openMapIcon} alt="" />
                  <p>{t('open_map')}</p>
                </div>
              ) : (
                <div className={'map-list-toggle'}>
                  <img src={openListIcon} alt="" />
                  <p>{t('open_list')}</p>
                </div>
              )}
            </div>
          </div>
          {mapListToggle ? (
            <div>
              {allConstructions.last_page && allConstructions.last_page > 1 ? (
                <ConstructionPagination
                  data2={allConstructions}
                  getPage={getPageConstructors}
                  count={3}
                  styles={'padding'}
                />
              ) : (
                ''
              )}
              {spinner ? (
                <div className="spinner_content">
                  <div className="sweet-loading">
                    <PulseLoader color={'#BE1E2D'} css={override} loading={true} size={16} />
                  </div>
                </div>
              ) : allConstructions.data ? (
                allConstructions.data.map((item, i) => (
                  <div key={i} className={'mb-3'}>
                    <Link to={'/construction-details/' + item.id}>
                      <div key={i} className="single-feature style-two construction construction-card-container">
                        <div className={'construction-card-img'}>
                          <div className="construction-card-main-image">
                            <LazyLoadImage
                              alt={item.property_name}
                              height="220px"
                              src={
                                item.main_image
                                  ? baseStorageUrl + '/uploads/constructors/' + item.main_image
                                  : default_image
                              }
                            />
                          </div>
                          {item.const_agency ? (
                            <div className="construction-card-sec-image">
                              <LazyLoadImage
                                alt={item.const_agency && item.const_agency.first_name}
                                src={
                                  item.const_agency && item.const_agency.avatar
                                    ? baseStorageUrl + '/uploads/users/' + item.const_agency.avatar
                                    : default_image
                                }
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="construction-wrap construction-wrap-mobile">
                          <div>
                            <div>
                              <div className="flex-row-align-start">
                                <span className="constructionTitle lh-20">
                                  {item.translations.map((value) => {
                                    return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                      value.locale === localStorage.i18nextLng
                                      ? value.property_name
                                      : null;
                                  })}
                                </span>
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    (localStorage.token || isLogin()) &&
                                      addToFavorites(e, item.id, FAVORITE_ENTITY_TYPES.construction);
                                  }}
                                  className={'heart ml-1'}
                                >
                                  <i
                                    className={
                                      item?.isFavorite ? 'fa fa-heart activeHeart cursor' : 'fa fa-heart-o cursor'
                                    }
                                  />
                                </span>
                              </div>
                              <br />
                              <span className="constructionSubTitle">
                                {item.translations.map((value) => {
                                  return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                    value.locale === localStorage.i18nextLng
                                    ? value.sub_title
                                    : null;
                                })}
                              </span>
                              <br />
                              {!item.sold ? (
                                <span className="constructionPrice">
                                  {item.currency && item.currency.local !== 'en' ? ' ' + item.currency.name : ''}
                                  <NumberFormat
                                    value={item.price_start && item.price_start}
                                    displayType={'text'}
                                    prefix={
                                      item.currency && item.currency.local === 'en' ? item.currency.name + ' ' : ''
                                    }
                                    thousandSeparator={true}
                                  />{' '}
                                  - {item.currency && item.currency.local !== 'en' ? ' ' + item.currency.name : ''}
                                  <NumberFormat
                                    value={item.price_end && item.price_end}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                </span>
                              ) : (
                                <span className="constructionPrice">{t('sold')}</span>
                              )}
                            </div>
                          </div>
                          <p>
                            <i className="fa fa-map-marker" />{' '}
                            {item.translations.map((value) => {
                              return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                value.locale === localStorage.i18nextLng
                                ? value.address
                                : null;
                            })}
                          </p>
                          <p>
                            <i className="fa fa-calendar-o" aria-hidden="true">
                              {' '}
                              {item.start_date} : {item.end_date}
                            </i>
                          </p>
                          <p>
                            <img alt={'keyLogo'} src={publicUrl + '/assets/img/icons/key.png'} />{' '}
                            {t('available') + ' ' + item.available_apartments + '/' + item.apartment_counts}
                          </p>
                          <p>
                            <img alt={'areaLogo'} src={publicUrl + '/assets/img/icons/measured.png'} /> {item.area}{' '}
                            {t('m')}²
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                t('no_result')
              )}
            </div>
          ) : (
            <div className="" style={{ height: '475px' }}>
              <Map allConstructors={allConstructors} poligonCoordinates={poligonCoordinates} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConstructionList;
