import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import agentReducer from './agentReducer';
import announcementReducer from './announcementReducer';
import authReducer from './authReducer';
import categoryReducer from './categoryReducer';
import constructionReducer from './constructionReducer';
import localeReducer from './localeReducer';
import modalsReducer from './modalsReducer';
import notificationsReducer from './notificationsReducer';
import placesReducer from './placesReducer';
import profileReducer from './profileReducer';
import regionReducer from './regionReducer';
import requestReducer from './requestReducer';
import resourcesReducer from './resourcesReducer';
import typeReducer from './typeReducer';
import userDataReducer from './userDataReducer';
import verificationReducer from './verificationReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  modals: modalsReducer,
  locale: localeReducer,
  resources: resourcesReducer,
  announcement: announcementReducer,
  type: typeReducer,
  category: categoryReducer,
  construction: constructionReducer,
  agent: agentReducer,
  notifications: notificationsReducer,
  profile: profileReducer,
  verification: verificationReducer,
  region: regionReducer,
  places: placesReducer,
  request: requestReducer,
  user: userDataReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export const { dispatch, getState } = store;
