import axios from 'axios';
import {
  getAllConstructions,
  getConstruction,
  getConstructionCurrentPage,
  getConstructions,
  setConstructorParams,
} from 'reducers/constructionReducer';
import { setSpinner } from 'reducers/modalsReducer';

export const construction = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/construction', {
        id,
      });
      dispatch(getConstruction(response.data.construction));
      await dispatch(setSpinner(false));
    } catch (e) {
      dispatch(getConstructions([]));
    }
  };
};
export const constructions = (data, newData, page) => {
  return async (dispatch) => {
    try {
      data[newData[0]] = newData[1];
      const response = await axios.post('api/constructions', {
        page: page,
        data,
      });
      dispatch(getConstructionCurrentPage(2));
      dispatch(getConstructions(response.data.constructions));
      dispatch(setConstructorParams(data));
      dispatch(setSpinner(false));
    } catch (e) {
      dispatch(getConstructions([]));
    }
  };
};
export const all_constructions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/all-constructions', {
        filtered: true,
      });
      dispatch(getAllConstructions(response.data.responseData));
    } catch (e) {
      dispatch(getAllConstructions([]));
    }
  };
};
