import ForgotPassword from 'components/auth/forgotPassword';
import Login from 'components/auth/login';
import MailInfo from 'components/auth/mailInfo';
import PhoneNumberVerification from 'components/auth/phoneNumberVerification';
import ResetPassword from 'components/auth/resetPassword';

const Modals = () => {
  return (
    <div>
      <Login />
      <PhoneNumberVerification />
      <ForgotPassword />
      <ResetPassword />
      <MailInfo />
    </div>
  );
};

export default Modals;
