import { auth } from 'actions/auth';
import { add_favorite, remove_favorite } from 'actions/favorite';
import { dispatch, getState } from 'reducers';

export const addToFavorites = async (e, entityId, type) => {
  const user = getState().auth.currentUser;

  if (!user.id) {
    dispatch(auth());
  }

  try {
    if (e.target.className === 'fa fa-heart activeHeart cursor' && e.target.className !== 'heart') {
      e.target.className = 'fa fa-heart-o cursor';
      await remove_favorite(entityId, type);
    } else if (e.target.className !== 'heart') {
      e.target.className = 'fa fa-heart activeHeart cursor';
      await add_favorite(entityId, type);
    }
  } catch (e) {
    console.log(e.message);
  }
};
