import { auth } from 'actions/auth';
import { remove_favorite } from 'actions/favorite';
import axios from 'axios';
import clsx from 'clsx';
import Pagination from 'components/section-components/pagination';
import { baseStorageUrl } from 'configs/env';
import { FAVORITE_ENTITY_TYPES, LANGUAGES_LOCAL_CODES } from 'constants/common';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { getTranslationByLocale } from 'utils';

const FavoritesGrid = (props) => {
  const publicUrl = process.env.PUBLIC_URL;
  const history = useHistory();
  const { t } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const currencies = useSelector((state) => state.announcement.currencies);

  const [entity, setEntity] = useState(FAVORITE_ENTITY_TYPES.listing);

  const isListings = entity === FAVORITE_ENTITY_TYPES.listing;

  const onChangeEntity = (type) => {
    setData([]);
    setEntity(type);
  };

  const priceFormat = (item) => {
    let price;
    item.currency &&
      currencies.map((value) => {
        if (localStorage.i18nextLng === value.local || (localStorage.i18nextLng === 'us' && value.local === 'en')) {
          if (value.id === item.currency.id) {
            price = item.price;
          } else {
            price = Math.floor((item.price * item.currency.value) / value.value);
          }
        }
        return price;
      });
    return price;
  };

  const currencyFormat = (item) => {
    let currency = ' ';
    item.currency &&
      currencies.map((value) => {
        if (localStorage.i18nextLng === value.local || (localStorage.i18nextLng === 'us' && value.local === 'en')) {
          if (value.local !== 'en') currency = value.name;
        }
        return currency;
      });
    return ' ' + currency;
  };

  const PrefixFormat = (item) => {
    let currency = ' ';
    item.currency &&
      currencies.map((value) => {
        if (localStorage.i18nextLng === value.local || (localStorage.i18nextLng === 'us' && value.local === 'en')) {
          if (value.local === 'en') currency = value.name;
        }
        return currency;
      });
    return currency + ' ';
  };

  const linkTo = (e, id) => {
    e.stopPropagation();
    if (e.target.className !== 'fa fa-heart' && e.target.className !== 'activeHeart') {
      history.push(`/${isListings ? 'property-details' : 'construction-details'}/${id}`);
    }
  };

  const getFavorites = async (page = currentPage) => {
    try {
      setLoading(true);
      const response = await axios.get(`api/favorites/${isListings ? 'listings' : 'constructions'}`, {
        params: { page },
      });
      setData(response?.data?.favorites?.data || []);
      setTotal(response?.data?.favorites?.total || 0);
    } catch (e) {
      console.log(e?.message);
    } finally {
      setLoading(false);
    }
  };

  const removeFavorite = async (e, id) => {
    if (!user.id) {
      dispatch(auth());
    }
    try {
      if (data.length === 1) {
        setLoading(true);
        await remove_favorite(id, entity);
        await getFavorites(currentPage);
      } else {
        void remove_favorite(id, entity);
        setData(data.filter((el) => el.entity_id !== id));
        setTotal(total - 1);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    void getFavorites();
  }, [isListings]);

  return (
    <div className="favorites-page-wrap">
      <ul className="nav tabs-nav">
        <li>
          <button
            className={clsx('nav-link tab-button', { active: isListings })}
            onClick={() => onChangeEntity(FAVORITE_ENTITY_TYPES.listing)}
            disabled={loading && !isListings}
          >
            <div className="border-bottom-line">{t('footer_announcements')}</div>
          </button>
        </li>
        <li>
          <button
            className={clsx('nav-link tab-button', { active: !isListings })}
            onClick={() => onChangeEntity(FAVORITE_ENTITY_TYPES.construction)}
            disabled={loading && isListings}
          >
            <div className="border-bottom-line">{t('construction')}</div>
          </button>
        </li>
      </ul>
      <div className="container">
        <Pagination
          withoutPaddingTop
          total={total}
          getPage={getFavorites}
          setPage={setCurrentPage}
          forcePage={currentPage - 1}
        />
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12  mg-top-10">
            {loading ? (
              <div className="spinner_content">
                <div className="sweet-loading">
                  <PulseLoader color={'#BE1E2D'} loading={true} size={16} />
                </div>
              </div>
            ) : (
              <div className="row mg-auto">
                {data?.length ? (
                  data.map((item, i) => {
                    const details = isListings ? item.announcement : item.constructor;

                    return (
                      <div key={i} className="col-xl-6 col-sm-12 col-lg-6 col-12 col-md-6">
                        <div
                          className="single-feature-announcement listing-content favoritesCard"
                          onClick={(e) => {
                            linkTo(e, details.id);
                          }}
                        >
                          <div className="thumb" style={{ height: 250 }}>
                            <img
                              style={{ height: '100%', width: '100%' }}
                              src={
                                details?.main_image
                                  ? `${baseStorageUrl}/uploads/${isListings ? 'announcements' : 'constructors'}/` +
                                    details.main_image
                                  : '/assets/img/default.png'
                              }
                              alt="..."
                            />
                            {isListings && (
                              <span className="forSale">
                                <li className="point">
                                  <span style={{ color: '#011728' }}>
                                    {details.category &&
                                      details.category.translations.map((value) => {
                                        return (localStorage.i18nextLng === 'us' && value.locale === 'en') ||
                                          value.locale === localStorage.i18nextLng
                                          ? value.name
                                          : null;
                                      })}
                                  </span>
                                </li>
                              </span>
                            )}
                            <span
                              onClick={(e) => {
                                removeFavorite(e, details.id);
                              }}
                              className="activeHeart"
                            >
                              <i className="fa fa-heart" />
                            </span>
                          </div>
                          <Link to={`/${isListings ? 'property-details' : 'construction-details'}/${details.id}`}>
                            <div className="details">
                              {!isListings && (
                                <h6 className={'favorites_construction_title'}>
                                  {getTranslationByLocale(details.translations, 'property_name')}
                                </h6>
                              )}
                              {isListings ? (
                                <h6 className="price">
                                  <NumberFormat
                                    value={priceFormat(details)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={PrefixFormat(details)}
                                    suffix={currencyFormat(details)}
                                  />
                                </h6>
                              ) : !details.sold ? (
                                <span className="price">
                                  <NumberFormat
                                    value={details.price_start}
                                    displayType={'text'}
                                    prefix={
                                      details.currency && details.currency.local === LANGUAGES_LOCAL_CODES.am
                                        ? details.currency.name + ' '
                                        : ' '
                                    }
                                    thousandSeparator={true}
                                  />
                                  <NumberFormat
                                    value={details.price_end}
                                    prefix={
                                      details.currency &&
                                      (details.currency.local === LANGUAGES_LOCAL_CODES.am
                                        ? ' - ' + ' ' + details.currency.name + ' '
                                        : '')
                                    }
                                    displayType={'text'}
                                    thousandSeparator={true}
                                  />
                                </span>
                              ) : (
                                <span className="price">{t('sold')}</span>
                              )}
                              <h6 className="readeal-top">
                                <i className="fa fa-map-marker" />
                                {details.address && details.address.length > 40
                                  ? details.address.slice(0, 40) + '...'
                                  : details.address}
                              </h6>
                              <ul
                                className={clsx({
                                  'info-list-announcement': isListings,
                                  'info-list-construction': !isListings,
                                })}
                              >
                                {!isListings && details.open_to_visit && details.close_to_visit ? (
                                  <li>
                                    <p>
                                      <i className="fa fa-clock-o" aria-hidden="true" />{' '}
                                      {details.open_to_visit.split(' ')[1].slice(0, 5)} :{' '}
                                      {details.close_to_visit.split(' ')[1].slice(0, 5)}
                                    </p>
                                  </li>
                                ) : null}
                                {!isListings && (
                                  <>
                                    <li>
                                      <p>
                                        <i className="fa fa-calendar-o" aria-hidden="true" /> {details.start_date} :{' '}
                                        {details.end_date}
                                      </p>
                                    </li>
                                    <li>
                                      <p>
                                        <img alt={'keyLogo'} src={publicUrl + '/assets/img/icons/key.png'} />{' '}
                                        {t('available2') +
                                          ' ' +
                                          details.available_apartments +
                                          '/' +
                                          details.apartment_counts}
                                      </p>
                                    </li>
                                  </>
                                )}
                                <li>
                                  <img alt={details.area} src={publicUrl + '/assets/img/icons/measured.png'} />{' '}
                                  {details.area} {t('m')}²
                                </li>
                                {isListings && details.rooms ? (
                                  <li>
                                    <i className="fa fa-bed" /> {details.rooms} {t('bed')}
                                  </li>
                                ) : null}
                                {isListings && details.bathroom ? (
                                  <li>
                                    <i className="fa fa-bath" /> {details.bathroom} {t('bath')}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 text-center mt-3">
                    <h3>{t('no_favorite')}</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesGrid;
