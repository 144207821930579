import './style.css';

import { Marker } from '@react-google-maps/api';

import markericon from '../../assets/66931-map-google-icons-maps-computer-marker-maker.png';
const CustomMarker = ({ number = 0, ...props }) => {
  const icon = {
    url: markericon,
    size: new window.google.maps.Size(30, 30),
    labelOrigin: new window.google.maps.Point(16, -20),
  };

  const label = {
    text: `${number}`,
    color: '#000',
    fontSize: '16px',
    fontWeight: 'bold',
    className: 'custom-label',
  };

  return <Marker {...props} icon={icon} label={label} />;
};
export default CustomMarker;
