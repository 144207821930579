import 'components/translations/i18n';

import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import EditAnnouncement from 'components/section-components/edit-announcement';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EditProperty = () => {
  const { t } = useTranslation();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    searchBar: 'hidden-for-scroll',
    mainNavbar: ' ',
  };

  return (
    <div>
      <Navbar data={data} t={t} />
      <EditAnnouncement t={t} />
      <Footer t={t} />
    </div>
  );
};

export default EditProperty;
