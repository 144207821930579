import axios from 'axios';
import { baseApiUrl } from 'configs/env';

// AXIOS GLOBAL CONFIG
axios.defaults.baseURL = baseApiUrl;

export const useInterceptorsRequest = (request) => {
  const token = localStorage.getItem('token');
  if (token) request.headers['Authorization'] = `Bearer ${token}`;
  return request;
};

axios.interceptors.request.use(useInterceptorsRequest);

export default axios;
