import axios from 'axios';
import { getAllNotifications, getUnreadNotifications } from 'reducers/notificationsReducer';

export const unreadNotifications = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('api/unread-notifications');
      dispatch(getUnreadNotifications(response.data.notifications));
    } catch (e) {
      dispatch(getUnreadNotifications([]));
    }
  };
};

export const allNotifications = (page) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`api/notifications?page=${page}`);
      dispatch(getAllNotifications(response.data.allNotifications));
    } catch (e) {
      dispatch(getAllNotifications([]));
    }
  };
};

export const readNotifications = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`api/read-notification/` + id);
      dispatch(getUnreadNotifications(response.data.unreadNotification));
    } catch (e) {
      console.log(e);
    }
  };
};
export const readAllNotifications = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('api/read-all-notifications');
      dispatch(getAllNotifications(response.data.unreadNotification));
      dispatch(getUnreadNotifications([]));
    } catch (e) {
      console.log(e);
    }
  };
};
