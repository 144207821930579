import axios from 'axios';
import { setLimitTextModal, setSpinner, setTextModal } from 'reducers/modalsReducer';

export const conditionText = (slug) => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const response = await axios.post('api/text', {
        slug,
      });
      dispatch(setTextModal(response.data.text));
      dispatch(setSpinner(false));
    } catch (e) {
      dispatch(setSpinner(false));
      console.log(e.response, 'eeeeeeerespons');
    }
  };
};
export const limit_text = (slug) => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const response = await axios.post('api/text', {
        slug,
      });
      dispatch(setLimitTextModal(response.data.text));
      dispatch(setSpinner(false));
    } catch (e) {
      dispatch(setSpinner(false));
      console.log(e.response, 'eeeeeeerespons');
    }
  };
};
