import axios from 'axios';
import { setErrors } from 'reducers/authReducer';
import { setVerificationErrors, setVerify, verificationCode } from 'reducers/verificationReducer';

import { auth } from './auth';

export const verification_code = (id, phone) => {
  return async (dispatch) => {
    await axios
      .post('/api/phone-number-verification-code', {
        id,
        phone,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setErrors(response.data.errors));
          dispatch(setVerificationErrors(response.data.errors));
        } else {
          dispatch(verificationCode(response.data));
        }
      });
  };
};

export const verify = (id, code, phone) => {
  return async (dispatch) => {
    await axios
      .post('/api/check-verification-code', {
        id,
        code,
        phone,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setErrors(response.data.errors));
          dispatch(setVerificationErrors(response.data.errors));
        } else {
          dispatch(setVerify(response.data));
          dispatch(auth());
        }
      })
      .catch((e) => {
        console.log(e, '111');
      });
  };
};
