import axios from 'axios';

export const add_favorite = async (entityId, type) => {
  return await axios.post('/api/add-favorite', {
    entityId,
    type,
  });
};
export const remove_favorite = async (entityId, type) => {
  return await axios.post('/api/remove-favorite', {
    entityId,
    type,
  });
};
