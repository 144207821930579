import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import AllAgents from 'components/section-components/all-agents';
import SearchAgent from 'components/section-components/search-agent';
import TopAgencies from 'components/section-components/top-agencies';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AgentsList = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    searchBar: 'hidden-for-scroll',
    searchAgent: ' ',
    mainNavbar: ' ',
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('meta_agents_list')}</title>
        <meta name="description" content={t('meta_agents_list_description')} />
      </Helmet>
      <Navbar t={t} data={data} />
      <SearchAgent t={t} />
      <AllAgents t={t} />
      <TopAgencies t={t} />
      <Footer t={t} />
    </div>
  );
};

export default AgentsList;
