import 'components/translations/i18n';

import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import AddLand from 'components/section-components/add-land';
import AddNew from 'components/section-components/add-new';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const AddProperty = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const { type } = useParams();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    searchBar: 'hidden-for-scroll',
    mainNavbar: ' ',
  };
  return (
    <div>
      <Navbar data={data} t={t} />
      {type !== '4' ? <AddNew t={t} /> : <AddLand t={t} />}
      <Footer t={t} />
    </div>
  );
};

export default AddProperty;
