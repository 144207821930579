import React from 'react';

const Apartment = ({ t, searchParams, searchBarChange }) => {
  return (
    <div className="container">
      <div className="rld-single-input mg-top-10">
        <label>
          {t('area')} {t('m')}²
        </label>
        <div className="sq-single-select row">
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.minSize ? searchParams.minSize : ''}
              name="minSize"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  searchBarChange(e.target.name, e.target.value);
                }
              }}
              type="text"
              placeholder={t('min')}
            />
          </div>
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.maxSize ? searchParams.maxSize : ''}
              name="maxSize"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  searchBarChange(e.target.name, e.target.value);
                }
              }}
              type="text"
              placeholder={t('max')}
            />
          </div>
        </div>
      </div>
      <div className="sq-single-select row mg-top-20">
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('number_of_bedrooms')}</label>
          <select
            className="select single-select add-property-select"
            name="rooms"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </select>
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('number_of_bathrooms')}</label>
          <select
            className="select single-select add-property-select"
            name="bathroom"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>
        </div>
      </div>
      <div className="sq-single-select row mg-top-20">
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('floor')}</label>
          <select
            className="select single-select add-property-select"
            name="floor"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={0}>{t('basement')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('storeys')}</label>
          <select
            className="select single-select add-property-select"
            name="storeys"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={13}>13</option>
            <option value={14}>14</option>
            <option value={15}>15</option>
            <option value={16}>16</option>
            <option value={17}>17</option>
            <option value={18}>18</option>
            <option value={19}>19</option>
            <option value={20}>20</option>
            <option value={21}>21</option>
            <option value={22}>22</option>
            <option value={23}>23</option>
            <option value={24}>24</option>
          </select>
        </div>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('building_type')}</label>
        <select
          className="select single-select add-property-select"
          name="building_type"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'new_building'}>{t('new_building')}</option>
          <option value={'monolith'}>{t('monolith')}</option>
          <option value={'stone'}>{t('stone')}</option>
          <option value={'panel'}>{t('panel')}</option>
          <option value={'other'}>{t('other')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('condition')}</label>
        <select
          className="select single-select add-property-select"
          name="condition"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'zero_condition'}>{t('zero_condition')}</option>
          <option value={'bad'}>{t('bad')}</option>
          <option value={'middle'}>{t('middle')}</option>
          <option value={'good'}>{t('good')}</option>
          <option value={'excellent'}>{t('excellent')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('furniture')}</label>
        <select
          className="select single-select add-property-select"
          name="furniture"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={true}>{t('furniture_available')}</option>
          <option value={false}>{t('furniture_no_available')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20 pd-bottom-20">
        <label>{t('ceiling_height')}</label>
        <select
          className="select single-select add-property-select"
          name="ceiling_height"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={2.6}>2.6</option>
          <option value={2.8}>2.8</option>
          <option value={3.0}>3.0</option>
          <option value={3.2}>3.2</option>
          <option value={3.4}>3.4</option>
        </select>
      </div>
    </div>
  );
};
export default Apartment;
