import axios from 'axios';
import { getAgents, getSuperAgents, setAgentSpinner, setSuperAgentSpinner } from 'reducers/agentReducer';

export const get_agents = (page = 1) => {
  return async (dispatch) => {
    try {
      dispatch(setAgentSpinner(true));
      const response = await axios.post('api/brokers', {
        page,
      });

      dispatch(getAgents(response.data.brokers));
      dispatch(setAgentSpinner(false));
    } catch (e) {
      dispatch(getAgents([]));
    }
  };
};
export const get_super_agents = (page = 1) => {
  return async (dispatch) => {
    try {
      dispatch(setSuperAgentSpinner(true));
      const response = await axios.post('api/super-brokers', {
        page,
      });
      dispatch(getSuperAgents(response.data.brokers));
      dispatch(setSuperAgentSpinner(false));
    } catch (e) {
      dispatch(getSuperAgents([]));
    }
  };
};
