import axios from 'axios';
import { getAgent, getAllAgents, getNames } from 'reducers/agentReducer';
import { setSpinner } from 'reducers/modalsReducer';
import {
  getAgencies,
  getAgency,
  getAgencyAnnouncements,
  getAllCompanies,
  getAnnouncements,
  getCompany,
  getConstAgencies,
  getConstAgency,
  getPartners,
  getTextPage,
  getTextPages,
  getTopAgencies,
  getTopCompanies,
} from 'reducers/resourcesReducer';

export const partners = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/partners');
      dispatch(getPartners(response.data.partners));
    } catch (e) {
      dispatch(getPartners([]));
    }
  };
};

export const agencies = (page) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/agencies', {
        page: page,
      });
      dispatch(getAgencies(response.data.agencies));
      dispatch(setSpinner(false));
    } catch (e) {
      dispatch(getAgencies([]));
    }
  };
};
export const agenciesAll = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/agenciesAll');
      dispatch(getAgencies(response.data.agencies));
    } catch (e) {
      dispatch(getAgencies([]));
    }
  };
};
export const topAgencies = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/top_agencies');
      dispatch(getTopAgencies(response.data.top_agencies));
    } catch (e) {
      dispatch(getTopAgencies([]));
    }
  };
};

export const agents = (page) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/brokers-names', {
        page: page,
      });
      dispatch(getNames(response.data.brokers));
    } catch (e) {
      dispatch(getNames([]));
    }
  };
};

export const allAgents = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/brokers_list');
      dispatch(getAllAgents(response.data.brokers));
    } catch (e) {
      dispatch(getAllAgents([]));
    }
  };
};

export const topCompanies = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/top_companies');
      dispatch(getTopCompanies(response.data.top_companies));
    } catch (e) {
      dispatch(getTopCompanies([]));
    }
  };
};
export const allCompanies = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/all_companies');
      dispatch(getAllCompanies(response.data.all_companies));
    } catch (e) {
      dispatch(getAllCompanies([]));
    }
  };
};

export const announcements = (coordinates = []) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/announcements', {
        coordinates,
      });
      dispatch(getAnnouncements(response.data.announcements));
    } catch (e) {
      dispatch(getAnnouncements([]));
    }
  };
};
export const agent = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/broker', {
        id,
      });
      dispatch(getAgent(response.data.broker));
    } catch (e) {
      dispatch(getAgent([]));
    }
  };
};
export const agency = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/agency', {
        id,
      });
      dispatch(getAgency(response.data.agency));
    } catch (e) {
      dispatch(getAgency([]));
    }
  };
};
export const agency_announcements = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/agency-announcements', {
        id,
      });
      dispatch(getAnnouncements(response.data.announcements));
      console.log(response.data.announcements);
    } catch (e) {
      dispatch(getAnnouncements([]));
    }
  };
};
export const agency_brokers_announcements = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/agency-brokers-announcements', {
        id,
      });
      dispatch(getAgencyAnnouncements(response.data.announcements));
    } catch (e) {
      dispatch(getAgencyAnnouncements([]));
    }
  };
};

export const company = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/company', {
        id,
      });
      await dispatch(getCompany(response.data.company));
    } catch (e) {
      dispatch(getCompany([]));
    }
  };
};
export const textPages = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/text_pages');
      await dispatch(getTextPages(response.data.pages));
    } catch (e) {
      dispatch(getTextPages([]));
    }
  };
};
export const textPage = (slug) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/text_page', {
        slug: slug,
      });
      await dispatch(getTextPage(response.data.page));
    } catch (e) {
      dispatch(getTextPage([]));
    }
  };
};
export const const_agency = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/constructor_agency', {
        id,
      });
      dispatch(getConstAgency(response.data.const_agency));
    } catch (e) {
      dispatch(getConstAgency([]));
    }
  };
};
export const const_agencies = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/constructor_agencies');
      dispatch(getConstAgencies(response.data.const_agencies));
    } catch (e) {
      dispatch(getConstAgencies([]));
    }
  };
};
