import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const data = {
  navBarFixedClass: 'navbar-area-fixed',
  blackLogo: ' ',
  logo: 'hidden-for-scroll ',
  searchBar: 'hidden-for-scroll',
  mainNavbar: ' ',
};

const userTypes = [
  {
    type: 'individual',
    imageSrc: '/assets/img/author/default_avatar.png',
    translationKey: 'individual_user',
  },
  {
    type: 'agent',
    imageSrc: '/assets/img/broker.jpg',
    translationKey: 'broker',
  },
];

const SellByUserOrAgent = () => {
  const { t } = useTranslation();
  const { category, type } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar t={t} data={data} />
      <div className="buy-sell-area pd-bottom-70">
        <div className="container">
          <div className="section-title text-center">
            <h2 className="preview">{t('add_listing')}</h2>
            <div className="partner-red-line mt-4" />
          </div>
          <div className="row justify-content-center buy-sell-area-content">
            {userTypes.map((value) => {
              return (
                <div key={value.type} className="col-xl-3 col-lg-4 col-sm-6">
                  <Link
                    to={
                      value.type === 'agent' ? `/choose-agent/${category}/${type}` : `/add-property/${category}/${type}`
                    }
                  >
                    <div className="single-author style-two text-center">
                      <div className="thumb">
                        <img src={value.imageSrc} alt={value.type} />
                      </div>
                      <div className="author-details">
                        <h4 className="preview">{t(value.translationKey)}</h4>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer t={t} />
    </div>
  );
};
export default SellByUserOrAgent;
