import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import BuyOrSell from 'components/section-components/buy-or-sell';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Sell = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    searchBar: 'hidden-for-scroll',
    mainNavbar: ' ',
  };
  return (
    <div>
      <Navbar t={t} data={data} />
      <BuyOrSell t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Sell;
