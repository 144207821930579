import { dateFormatFE } from 'configs/date';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const defaultDateState = [null, null];

const DefaultType = ({ searchBarChange, getCurrencies, t, setSearchParams, searchParams }) => {
  const [[startDate, endDate], setDateState] = useState([
    searchParams.start_date ? moment(searchParams.start_date, dateFormatFE).toDate() : null,
    searchParams.end_date ? moment(searchParams.end_date, dateFormatFE).toDate() : null,
  ]);

  const onCalendarClose = () => {
    if (!startDate || !endDate) {
      setDateState(defaultDateState);

      if (searchParams.start_date || searchParams.end_date) {
        setSearchParams((prev) => ({ ...prev, start_date: null, end_date: null }));
      }
    }
  };

  const onChangeDatePicker = (dateRange) => {
    setDateState(dateRange);

    const [start, end] = dateRange;
    if (start && end) {
      const startDateFormatted = moment(start).format(dateFormatFE);
      const endDateFormatted = moment(end).format(dateFormatFE);

      setSearchParams((prev) => ({ ...prev, start_date: startDateFormatted, end_date: endDateFormatted }));
    } else if (!start && !end) {
      setSearchParams((prev) => ({ ...prev, start_date: null, end_date: null }));
    }
  };

  return (
    <div className="container">
      {searchParams.rent_type === 'daily_rent' && (
        <div className="rld-single-input mg-top-30">
          <label>{t('rental_period')}</label>
          <div className="sq-single-select">
            <DatePicker
              placeholderText={t('choose')}
              onCalendarClose={onCalendarClose}
              wrapperClassName="dp-wrapper"
              calendarClassName="dp-calendar"
              isClearable
              disabledKeyboardNavigation
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={onChangeDatePicker}
              minDate={moment().toDate()}
              maxDate={startDate && !endDate ? moment(startDate).add(1, 'month').toDate() : undefined}
            />
          </div>
        </div>
      )}
      <div className="rld-single-input mg-top-10">
        <label>{t('currency')}</label>
        <div className="sq-single-select">
          <select
            className="select single-select add-property-select"
            name="currency"
            onChange={(event) => {
              searchBarChange(event.target.name, event.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            {getCurrencies.map((value, key) => {
              return (
                <option key={key} value={value.id}>
                  {value.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <label className="mg-top-10">{t('price')}</label>
      <div className="rld-single-input mg-top-5">
        <div className="sq-single-select row">
          <div className="col-md-6 col-xl-6 col-lg-6">
            <label>{t('min')}</label>
            <input
              value={searchParams.minPrice ? searchParams.minPrice : ''}
              type="text"
              placeholder={t('max')}
              min="0"
              name="minPrice"
              onChange={(event) => {
                if (event.target.value >= 0) {
                  searchBarChange(event.target.name, event.target.value);
                }
              }}
            />
          </div>
          <div className="col-md-6 col-xl-6 col-lg-6">
            <label>{t('max')}</label>
            <input
              value={searchParams.maxPrice ? searchParams.maxPrice : ''}
              name="maxPrice"
              onChange={(event) => {
                if (event.target.value >= 0) {
                  searchBarChange(event.target.name, event.target.value);
                }
              }}
              type="text"
              placeholder={t('max')}
            />
          </div>
        </div>
      </div>
      <div className="rld-single-input mg-top-10">
        <label>
          {t('area')} {t('m')}²
        </label>
        <div className="sq-single-select row">
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.minSize ? searchParams.minSize : ''}
              name="minSize"
              onChange={(event) => {
                if (event.target.value >= 0) {
                  searchBarChange(event.target.name, event.target.value);
                }
              }}
              type="text"
              placeholder={t('min')}
            />
          </div>
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.maxSize ? searchParams.maxSize : ''}
              name="maxSize"
              onChange={(event) => {
                if (event.target.value >= 0) {
                  searchBarChange(event.target.name, event.target.value);
                }
              }}
              type="text"
              placeholder={t('max')}
            />
          </div>
        </div>
      </div>
      <div className="sq-single-select row mg-top-20">
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('number_of_bedrooms')}</label>
          <select
            className="select single-select add-property-select"
            name="rooms"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </select>
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('number_of_bathrooms')}</label>
          <select
            className="select single-select add-property-select"
            name="bathroom"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4+</option>
          </select>
        </div>
      </div>
      <div className="sq-single-select row mg-top-20">
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('floor')}</label>
          <select
            className="select single-select add-property-select"
            name="floor"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={0}>{t('basement')}</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
            <option value={11}>11</option>
            <option value={12}>12</option>
            <option value={13}>13</option>
            <option value={14}>14</option>
            <option value={15}>15</option>
            <option value={16}>16</option>
          </select>
        </div>
        <div className="col-md-6 col-xl-6 col-lg-6">
          <label>{t('building_type')}</label>
          <select
            className="select single-select add-property-select"
            name="building_type"
            onChange={(e) => {
              searchBarChange(e.target.name, e.target.value);
            }}
          >
            <option value="">{t('choose')}</option>
            <option value={'new_building'}>{t('new_building')}</option>
            <option value={'monolith'}>{t('monolith')}</option>
            <option value={'stone'}>{t('stone')}</option>
            <option value={'panel'}>{t('panel')}</option>
            <option value={'other'}>{t('other')}</option>
          </select>
        </div>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('condition')}</label>
        <select
          className="select single-select add-property-select"
          name="condition"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'zero_condition'}>{t('zero_condition')}</option>
          <option value={'bad'}>{t('bad')}</option>
          <option value={'middle'}>{t('middle')}</option>
          <option value={'good'}>{t('good')}</option>
          <option value={'excellent'}>{t('excellent')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20 pd-bottom-20">
        <label>{t('ceiling_height')}</label>
        <select
          className="select single-select add-property-select"
          name="ceiling_height"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={2.6}>2.6</option>
          <option value={2.7}>2.7</option>
          <option value={2.8}>2.8</option>
          <option value={2.9}>2.9</option>
          <option value={3.0}>3.0</option>
          <option value={3.2}>3.2</option>
          <option value={3.4}>3.4</option>
        </select>
      </div>
    </div>
  );
};
export default DefaultType;
