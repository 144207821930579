import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import ConstAgencyInfo from 'components/section-components/const-agency-info';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConstAgency = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    mainNavbar: ' ',
    no_search: true,
  };

  return (
    <div>
      <Navbar t={t} data={data} />
      <ConstAgencyInfo t={t} />
      <Footer t={t} />
    </div>
  );
};

export default ConstAgency;
