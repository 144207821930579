export const getTranslationByLocale = (translations, key = 'name') => {
  if (!Array.isArray(translations)) {
    return null;
  }

  const lang = localStorage.getItem('i18nextLng');
  const fixedLang = lang === 'us' ? 'en' : lang;

  const translation = translations.find((el) => el.locale === fixedLang);
  return translation?.[key] || null;
};
