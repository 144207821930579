import axios from 'axios';
import {
  getMessage,
  request,
  setBrokerErrors,
  setCurrentMessagePage,
  setLoginErrors,
  setRegisterErrors,
  setUser,
  setUserBrokerMessages,
  setUserBrokerMoreMessages,
  unreadMessages,
} from 'reducers/authReducer';
import { setModal, setUserComments } from 'reducers/modalsReducer';
import { setMessageSpinner } from 'reducers/profileReducer';

export const login = (email, password) => {
  return async (dispatch) => {
    await axios
      .post('api/login', {
        email,
        password,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setLoginErrors(response.data.errors));
        } else {
          dispatch(setUser(response.data.user));
          dispatch(setModal(''));
          localStorage.setItem('token', response.data.token.original.access_token);
          if (response.data.user.phone_number_verified_at === null) {
            dispatch(setModal('phone_number_verification'));
          }
        }
      });
  };
};
export const socialLogin = (data) => {
  return async (dispatch) => {
    await axios
      .post('api/social-login', {
        data,
      })
      .then((response) => {
        dispatch(setUser(response.data.user));
        dispatch(setModal(''));
        localStorage.setItem('token', response.data.token);
        if (response.data.user.phone_number_verified_at === null) {
          dispatch(setModal('phone_number_verification'));
        }
      });
  };
};

export const auth = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('api/auth');
      dispatch(setUser(response.data.user));
    } catch (e) {
      // localStorage.removeItem('token')
    }
  };
};
export const forgotPassword = (email) => {
  return async (dispatch) => {
    await axios
      .post('api/forgot_password', {
        email: email,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setLoginErrors(response.data.errors));
        } else {
          dispatch(setModal('mail_info'));
        }
      });
  };
};

export const register = (firstName, lastName, email, password, repeatPassword) => {
  return async (dispatch) => {
    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      password_confirmation: repeatPassword,
    };
    await axios
      .post('api/register', {
        user,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setRegisterErrors(response.data.errors));
        } else {
          dispatch(setUser(response.data.user));
          localStorage.setItem('token', response.data.token.original.access_token);
        }
      });
  };
};

export const broker_register = (firstName, lastName, email, phone) => {
  return async (dispatch) => {
    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
    };
    await axios
      .post('api/api-agent-request', {
        user,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setBrokerErrors(response.data.errors));
        } else {
          dispatch(request(response.data.request_status));
        }
      });
  };
};

export const resetPassword = (email, password, repeatPassword) => {
  return async (dispatch) => {
    const user = {
      email: email,
      password: password,
      password_confirmation: repeatPassword,
    };
    await axios
      .post('api/reset_password', {
        user,
      })
      .then((response) => {
        if (response.data.status === 400) {
          dispatch(setLoginErrors(response.data.errors));
        } else {
          window.location.href = '/';
          dispatch(setUser(response.data.user));
          localStorage.setItem('token', response.data.token.original.access_token);
          if (response.data.user.phone_number_verified_at === null) {
            dispatch(setModal('phone_number_verification'));
          }
        }
      });
  };
};
export const getUserMessages = (user) => {
  return async (dispatch) => {
    await axios
      .post('api/get-messages', {
        user_id: user,
      })
      .then((response) => {
        dispatch(getMessage(response.data.messages));
        dispatch(setMessageSpinner(false));
      })
      .catch(() => {
        dispatch(setMessageSpinner(false));
      });
  };
};
export const getUserBrokerMessages = (user_id, broker_id, class_name, page = 1, see_more = 1) => {
  return async (dispatch) => {
    await axios
      .post('api/get-user-broker-messages', {
        user_id: user_id,
        broker_id: broker_id,
        page: page,
      })
      .then((response) => {
        if (see_more) {
          dispatch(setCurrentMessagePage(2));
          dispatch(setUserBrokerMessages(response.data.messages.data));
          document.getElementsByClassName(class_name)[0].scrollTop = 200;
        } else {
          dispatch(setCurrentMessagePage(++page));
          dispatch(setUserBrokerMoreMessages(response.data.messages));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
export const getUnreadMessages = (user_id) => {
  return async (dispatch) => {
    await axios
      .post('api/unread-messages', {
        user_id: user_id,
      })
      .then((response) => {
        dispatch(unreadMessages(response.data.unreadMessages));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
export const user_comments = (user_id) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/user_comments', {
        user_id,
      });
      dispatch(setUserComments(response.data.comments));
    } catch (e) {
      dispatch(setUserComments([]));
    }
  };
};
