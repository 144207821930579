import axios from 'axios';
import { getCategories } from 'reducers/categoryReducer';
import { setSpinner } from 'reducers/modalsReducer';

export const categories = () => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const language = localStorage.i18nextLng;
      const response = await axios.post('api/categories', {
        language,
      });
      await dispatch(getCategories(response.data.categories));
      dispatch(setSpinner(false));
    } catch (e) {
      console.log(e, 'eeeeeeerespons');
    }
  };
};
