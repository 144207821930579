import axios from 'axios';
import { setSpinner } from 'reducers/modalsReducer';
import { getTypes } from 'reducers/typeReducer';

export const types = () => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const language = localStorage.i18nextLng;
      const response = await axios.post('api/types', {
        language,
      });
      await dispatch(getTypes(response.data.types));
      dispatch(setSpinner(false));
    } catch (e) {
      console.log(e, 'eeeeeeerespons');
    }
  };
};
