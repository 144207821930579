import axios from 'axios';
import clsx from 'clsx';
import Pagination from 'components/section-components/pagination';
import { baseStorageUrl } from 'configs/env';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Table, UncontrolledTooltip } from 'reactstrap';

const bookingStatuses = {
  pending: 'pending',
  confirmed: 'confirmed',
  rejected: 'rejected',
  completed: 'completed',
};

const BookingRequests = ({ t }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [res, setRes] = useState(null);
  const [isPendingAction, setIsPendingAction] = useState(false);

  const getBookingRequests = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await axios.get('api/bookings/listings', {
        params: { page },
      });
      if (response.status === 200) {
        setRes(response?.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewBooking = async (id, type, newStatus) => {
    try {
      setIsPendingAction(true);
      const response = await axios.patch(`api/booking/${id}/${type}`);
      if (response.status === 200) {
        const newBookingsList = res?.data?.map((el) =>
          el.id === id
            ? {
                ...el,
                status: newStatus,
              }
            : el
        );

        setRes((prev) => ({
          ...prev,
          data: newBookingsList,
        }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsPendingAction(false);
    }
  };

  useEffect(() => {
    getBookingRequests();
  }, []);

  return (
    <div className="my-bookings-page-wrapper favorites-page-wrap">
      {isLoading ? (
        <div className="spinner_content">
          <div className="sweet-loading">
            <PulseLoader color={'#BE1E2D'} loading={true} size={16} />
          </div>
        </div>
      ) : (
        <>
          <Table>
            <thead>
              <tr className="broker-tab">
                <th></th>
                <th>{t('price')}</th>
                <th>{t('start_date')}</th>
                <th>{t('end_date')}</th>
                <th>{t('first_name')}</th>
                <th>{t('last_name')}</th>
                <th>{t('status')}</th>
                <th className="actionButtons" />
              </tr>
            </thead>
            <tbody style={{ padding: '2%' }}>
              {res?.data?.length > 0 &&
                res.data.map((item) => {
                  const listing = item.listing;

                  return (
                    <tr key={item.id}>
                      <td>
                        <img
                          onClick={() => {
                            history.push(`/property-details/${item.listing_id}`);
                          }}
                          className="booking-img"
                          src={baseStorageUrl + '/uploads/announcements/' + listing.main_image}
                          alt=" "
                        />{' '}
                      </td>
                      <td>
                        {listing.currency && listing.currency.name} {listing.price}
                      </td>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item?.user?.first_name || '-'}</td>
                      <td>{item?.user?.last_name || '-'}</td>
                      <td>
                        <span
                          className={clsx({
                            verifyStatusPending: item.status === bookingStatuses.pending,
                            verifyStatusActive: item.status === bookingStatuses.confirmed,
                            verifyStatusRejected: item.status === bookingStatuses.rejected,
                            verifyStatusCompleted: item.status === bookingStatuses.completed,
                          })}
                        >
                          {t(bookingStatuses[item.status])}
                        </span>
                      </td>
                      <td>
                        {item.status === bookingStatuses.pending && (
                          <div className={'button-group'}>
                            <button
                              id={`reject-${item.id}`}
                              type="submit"
                              className="bookingTableBtn"
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className="property-filter-menu buttons">
                                        <h1>{t('are_you_sure')}</h1>
                                        <p>{t('reject_booking_confirmation')}</p>
                                        <button
                                          disabled={isPendingAction}
                                          className="active"
                                          onClick={async () => {
                                            await reviewBooking(item.id, 'reject', bookingStatuses.rejected);
                                            onClose();
                                          }}
                                        >
                                          {t('yes')}
                                        </button>
                                        <button disabled={isPendingAction} className="active" onClick={onClose}>
                                          {t('no')}
                                        </button>
                                      </div>
                                    );
                                  },
                                });
                              }}
                            >
                              <i className="rejectIcon fa fa-ban fa-lg" aria-hidden="true" />
                            </button>
                            <UncontrolledTooltip placement="top" target={`reject-${item.id}`}>
                              {t('reject')}
                            </UncontrolledTooltip>
                            <span> </span>
                            <button
                              id={`verify-${item.id}`}
                              type="submit"
                              className="bookingTableBtn"
                              onClick={() => {
                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <div className="property-filter-menu buttons">
                                        <h1>{t('are_you_sure')}</h1>
                                        <p>{t('confirm_booking_confirmation')}</p>
                                        <button
                                          disabled={isPendingAction}
                                          className="active"
                                          onClick={async () => {
                                            await reviewBooking(item.id, 'confirm', bookingStatuses.confirmed);
                                            onClose();
                                          }}
                                        >
                                          {t('yes')}
                                        </button>
                                        <button disabled={isPendingAction} className="active" onClick={onClose}>
                                          {t('no')}
                                        </button>
                                      </div>
                                    );
                                  },
                                });
                              }}
                            >
                              <i className="confirmIcon fa fa-check-circle-o fa-lg" aria-hidden="true" />
                            </button>
                            <UncontrolledTooltip placement="top" target={`verify-${item.id}`}>
                              {t('verify')}
                            </UncontrolledTooltip>
                            <span> </span>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {res?.last_page && res.last_page > 1 ? (
            <Pagination
              withoutPaddingTop
              total={res?.total}
              getPage={getBookingRequests}
              forcePage={res?.current_page ? res?.current_page - 1 : 0}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default BookingRequests;
