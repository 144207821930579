import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Salary = () => {
  const lang = useSelector((state) => state.locale.locale);
  const [amount, setAmount] = useState(80000);
  const [it, isIt] = useState(false);
  const [active, setActive] = useState(1);
  const [val, setVal] = useState(false);
  const { t } = useTranslation();
  function formatAmount(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const windowWidth = 500;

  function myIncomeTax() {
    let tax = it ? 0.1 : 0.21;
    return Math.floor(amount * tax);
  }

  function mySocialFee() {
    if (amount < 500000) {
      return Math.floor(amount * 0.045);
    }
    if (amount >= 500000) {
      return Math.floor(amount * 0.1);
      //petutyan koxmic 27500
    }
  }
  function kensatoshak() {
    return Math.floor(amount * 0.1);
  }

  function zinvori() {
    if (amount < 100000) {
      return 1500;
    }
    if (amount >= 100000 && amount < 200000) {
      return 3000;
      //petutyan koxmic 27500
    }
    if (amount >= 200000 && amount < 500000) {
      return 5500;
      //petutyan koxmic 27500
    }
    if (amount >= 500000 && amount < 1000000) {
      return 8500;
      //petutyan koxmic 27500
    }
    if (amount >= 1000000) {
      return 15000;
      //petutyan koxmic 27500
    }
  }

  function net() {
    let myNet = amount - (myIncomeTax() + zinvori() + mySocialFee());
    if (myNet < 0) {
      myNet = 0;
      return myNet;
    }
    return amount - (myIncomeTax() + zinvori() + mySocialFee());
  }

  function getBackgroundColor() {
    return active === 1 ? '#A12531' : '#ededef';
  }
  function getBackgroundColor2() {
    return active === 2 ? '#A12531' : '#ededef';
  }
  function getdivColor() {
    return active === 1 ? 'white' : '#707070';
  }
  function getdivColor2() {
    return active === 2 ? 'white' : '#707070';
  }

  function hiperdiv(clean, declared, tax, sTax, savings, mil) {
    switch (lang) {
      case 'eng':
        return `To get ${clean} AMD clear salary you must declare ${declared} AMD. Income Tах  will be ${tax} AMD. Social Security Tax will be ${sTax} AMD. The monthly supplement of savings account will be ${savings} AMD. Military personals Insurance fund will be ${mil} AMD.`;
      case 'ru':
        return `Чтобы получить ${clean} АМД чистую зарплату, вы должны объявить ${declared} АМД. Подоходный налог будет ${tax} АМД. Налог на социальное обеспечение составит ${sTax} АМД. Ежемесячная добавка сберегательного счета составит ${savings} АМД. Фонд страхования военного персонала будет ${mil} АМД.`;
      case 'am':
        return `${clean} դրամ մաքուր աշխատավարձ ստանալու համար պետք է գրանցել ${declared} դրամ: Եկամտային հարկը կկազմի ${tax} դրամ:Սոցիալական վճարը ${sTax} դրամ: Կուտակային հաշվի ամսական համալրումը կկազմի ${savings} դրամ: Զինծառայողների Ապահովագրության Հիմնադրամի դրոշմանիշային վճարը ${mil} դրամ է։`;
      default:
    }
  }

  function getActive(x, y) {
    setActive(x);
    isIt(y);
  }
  const data = {
    mainNavbar: ' main-navbar-area',
    searchBar: 'hidden-for-scroll',
  };
  return (
    <>
      <Navbar t={t} data={data} />

      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          alignItems: 'center',
          paddingTop: '90px',
          margin: '0 auto',
        }}
      >
        <div>{t('it_tax')}</div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
            height: 32,
            borderRadius: 8,
            justifyContent: 'space-between',
            backgroundColor: '#ededef',
            width: '25%',
          }}
        >
          <button
            style={{
              borderRadius: 8,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              backgroundColor: getBackgroundColor(),
              color: getdivColor(),
              border: 'none',
              width: '50%',
            }}
            onClick={() => {
              getActive(1, false);
            }}
          >
            {t('no')}
          </button>
          <button
            style={{
              borderRadius: 8,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              backgroundColor: getBackgroundColor2(),
              color: getdivColor2(),
              border: 'none',
              width: '50%',
            }}
            onClick={() => {
              getActive(2, true);
            }}
          >
            {t('yes')}
          </button>
        </div>

        <div style={{ marginTop: 20, width: '50%' }}>
          <h5>
            {t('salary')} {formatAmount(amount)}(֏)
          </h5>
          <input
            type="range"
            value={amount}
            onChange={(value) => {
              setAmount(value.target.value);
            }}
            max={5000000}
            min={80000}
            step={5000}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '90%' }}>
          <button
            onClick={() => {
              setVal(true);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 50,
              backgroundColor: '#A12531',
              borderRadius: 10,
              marginBottom: 10,
              width: '20%',
              color: 'white',
              fontSize: 18,
              border: 'none',
            }}
          >
            {t('calculate')}
          </button>
        </div>
        {val ? (
          <div>
            <div style={{ marginTop: 10, backgroundColor: 'white', borderRadius: 10, border: '1px solid silver' }}>
              <div style={{ padding: 10, width: windowWidth - windowWidth * 0.1 }}>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 18, color: '#A12531', paddingLeft: 5 }}>{t('income_tax')}(֏)</div>
                    <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(myIncomeTax())}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 18, color: '#A12531', paddingLeft: 5 }}>{t('social_fee')}(֏)</div>
                    <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(mySocialFee())}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 18, color: '#A12531', paddingLeft: 5 }}>{t('stamp_duty')}(֏)</div>
                    <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(zinvori())}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 18, color: '#A12531', paddingLeft: 5 }}>{t('net_balance')}(֏)</div>
                    <div style={{ fontSize: 18, color: '#A12531' }}>{formatAmount(net())}</div>
                  </div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      {hiperdiv(
                        formatAmount(amount),
                        formatAmount(net()),
                        formatAmount(myIncomeTax()),
                        formatAmount(mySocialFee()),
                        formatAmount(kensatoshak()),
                        formatAmount(zinvori())
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Footer t={t} />
    </>
  );
};

export default Salary;
