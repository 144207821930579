import { GoogleMap, InfoWindow, MarkerClusterer, Polygon, useLoadScript } from '@react-google-maps/api';
import CustomMarker from 'components/custom-marker/custom-marker';
import { baseStorageUrl } from 'configs/env';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

function Map({ allConstructors, poligonCoordinates }) {
  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [infoOpen, setInfoOpen] = useState(false);
  const publicUrl = process.env.PUBLIC_URL;
  const default_image = publicUrl + '/assets/img/default.png';

  function polygons() {
    let newPolygonsContainer = [];
    poligonCoordinates[0]?.forEach((item) => {
      const myPolygons = {};
      myPolygons.lat = item[0];
      myPolygons.lng = item[1];
      newPolygonsContainer.push(myPolygons);
    });
    return newPolygonsContainer;
  }

  function formatPrice(price) {
    const priceString = price?.toString();
    let formattedPrice = '';
    for (let i = priceString?.length - 1, j = 0; i >= 0; i--, j++) {
      if (j % 3 === 0 && j !== 0) {
        formattedPrice = '.' + formattedPrice;
      }
      formattedPrice = priceString[i] + formattedPrice;
    }
    return formattedPrice;
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDMp1z-5LbRT3y3y1giUpp9AeiW_qtP5M8',
  });

  const history = useHistory();
  const loadHandler = (map) => {
    setMapRef(map);
    // fitBounds(map);
  };
  const useFoo = () => {
    return useMemo(() => {
      const state = {
        lat: 40.135282,
        lng: 44.512432,
      };
      return state;
    }, []);
  };
  const foo = useFoo();

  const linkTo = (e, id) => {
    e.stopPropagation();
    history.push(`/construction-details/${id}`);
  };
  const options = {
    fillColor: '#BE1E2D',
    fillOpacity: 0.1,
    strokeColor: '#BE1E2D',
    strokeOpacity: 0.9,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    setSelectedPlace(place);
    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
  };
  const renderMap = () => {
    return (
      <GoogleMap
        onLoad={loadHandler}
        center={foo}
        zoom={12}
        mapContainerStyle={{
          height: '100%',
          width: '100%',
        }}
      >
        <MarkerClusterer averageCenter={true} clusterClass={'cluster'}>
          {(clusterer) =>
            allConstructors?.map((place) => (
              <CustomMarker
                number={
                  '֏ ' +
                  formatPrice(place?.price_start) +
                  (formatPrice(place?.price_end) ? ' - ' + formatPrice(place?.price_end) : '')
                }
                key={place.id}
                clusterer={clusterer}
                position={{ lat: +place.latitude, lng: +place.longitude }}
                onLoad={(marker) => markerLoadHandler(marker, place)}
                onClick={(event) => markerClickHandler(event, place)}
              />
            ))
          }
        </MarkerClusterer>
        {infoOpen && selectedPlace && (
          <InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setInfoOpen(false)} zIndex={9999}>
            <div>
              <img
                src={
                  selectedPlace.main_image
                    ? baseStorageUrl + '/uploads/constructors/' + selectedPlace.main_image
                    : default_image
                }
                alt={selectedPlace.property_name}
                width={'100%'}
                height={300}
                onClick={(e) => {
                  linkTo(e, selectedPlace.id);
                }}
              />
              <div>{selectedPlace.address}</div>
              <div style={{ width: '150px' }}>
                {'֏ ' +
                  formatPrice(selectedPlace.price_start) +
                  (formatPrice(selectedPlace.price_end) ? ' - ' + formatPrice(selectedPlace.price_end) : '')}
              </div>
            </div>
          </InfoWindow>
        )}
        <Polygon path={polygons()} options={options} />
      </GoogleMap>
    );
  };
  return isLoaded ? renderMap() : null;
}

export default Map;
