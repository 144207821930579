import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import AllAgencies from 'components/section-components/all-agencies';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AgenciesList = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const data = {
    navBarFixedClass: 'navbar-area-fixed',
    blackLogo: ' ',
    logo: 'hidden-for-scroll ',
    searchBar: 'hidden-for-scroll',
    mainNavbar: ' ',
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('meta_agencies_list')}</title>
        <meta name="description" content={t('meta_agencies_list_description')} />
      </Helmet>
      <Navbar t={t} data={data} />
      <AllAgencies t={t} />
      <Footer t={t} />
    </div>
  );
};

export default AgenciesList;
