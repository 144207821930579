import React from 'react';

const Land = ({ t, searchParams, searchBarChange }) => {
  return (
    <div className="container">
      <div className="rld-single-input mg-top-10">
        <label>{t('general_area')} m²</label>
        <div className="sq-single-select row">
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.minSize ? searchParams.minSize : ''}
              name="minSize"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  searchBarChange(e.target.name, e.target.value);
                }
              }}
              type="text"
              placeholder="Min"
            />
          </div>
          <div className="col-md-6 col-xl-6 col-lg-6">
            <input
              value={searchParams.maxSize ? searchParams.maxSize : ''}
              name="maxSize"
              onChange={(e) => {
                if (e.target.value >= 0) {
                  searchBarChange(e.target.name, e.target.value);
                }
              }}
              type="text"
              placeholder="Max"
            />
          </div>
        </div>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('front_position')}</label>
        <select
          className="select single-select add-property-select"
          name="front_position"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'primary_and_secondary'}>{t('primary_and_secondary')}</option>
          <option value={'primary'}>{t('primary')}</option>
          <option value={'secondary'}>{t('secondary')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('purpose')}</label>
        <select
          className="select single-select add-property-select"
          name="purpose"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'public_construction_of_settlements'}>{t('public_construction_of_settlements')}</option>
          <option value={'residential_construction_of_settlements'}>
            {t('residential_construction_of_settlements')}
          </option>
          <option value={'mixed_construction_of_settlements'}>{t('mixed_construction_of_settlements')}</option>
        </select>
      </div>
      <div className="sq-single-select mg-top-20">
        <label>{t('land_geometric')}</label>
        <select
          className="select single-select add-property-select"
          name="land_geometric_appearance"
          onChange={(e) => {
            searchBarChange(e.target.name, e.target.value);
          }}
        >
          <option value="">{t('choose')}</option>
          <option value={'0-2'}>{t('smooth')}</option>
          <option value={'2-5'}>2-5 {t('degrees')}</option>
          <option value={'5-10'}>5-10 {t('degrees')}</option>
        </select>
      </div>
    </div>
  );
};
export default Land;
