import axios from 'axios';
import { setPlaces } from 'reducers/placesReducer';

export const allPlaces = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post('api/places');
      dispatch(setPlaces(response.data.places));
      return response.data.places;
    } catch (e) {
      // return e.message
    }
  };
};
