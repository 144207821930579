import axios from 'axios';
import { setSpinner } from 'reducers/modalsReducer';
import { setSuperBrokersNames, setUserData } from 'reducers/userDataReducer';

export const user = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const response = await axios.post('api/user', {
        id,
      });
      dispatch(setUserData(response.data.user));
      dispatch(setSpinner(false));
    } catch (e) {
      dispatch(setSpinner(false));
    }
  };
};
export const super_brokers_names = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      const response = await axios.post('api/super-brokers-names', {
        id,
      });
      dispatch(setSuperBrokersNames(response.data.brokers));
    } catch (e) {
      dispatch(setSuperBrokersNames([]));
    }
  };
};
