import Footer from 'components/global-components/footer';
import Navbar from 'components/global-components/navbar';
import PageHeader from 'components/global-components/page-header';
import TextPageInfo from 'components/section-components/text-page-info';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TextPage = () => {
  window.scrollTo(0, 0);
  const { t } = useTranslation();
  const data = {
    mainNavbar: ' main-navbar-area',
    searchBar: 'hidden-for-scroll',
  };

  return (
    <div>
      <Navbar t={t} data={data} />
      <PageHeader />
      <TextPageInfo />
      <Footer t={t} />
    </div>
  );
};

export default TextPage;
