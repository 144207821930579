export const dateFormatFE = 'DD/MM/YYYY';
// export const dateFormatBE = 'YYYY-MM-DD';

// export const convertBEDateToFEDate = (date) => {
//   if (date) {
//     return moment(date, dateFormatBE).format(dateFormatFE);
//   } else {
//     return '-';
//   }
// };
