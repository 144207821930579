import './configs/axios.config';

import { auth } from 'actions/auth';
import AddNew from 'components/add-property';
import AgenciesList from 'components/agencies-list';
import AgencyPage from 'components/agency-page';
import AgentsList from 'components/agents-list';
import Announcements from 'components/announcements';
import BankRequest from 'components/bank-request';
import ChooseBroker from 'components/choose-broker';
import ComingSoon from 'components/coming_soon';
import ConstAgency from 'components/const-agency';
import Construction from 'components/construction';
import ConstructionDetails from 'components/construction-details';
import ConstructionDetailsTest from 'components/construction-details-test';
import Contact from 'components/contact';
import EditProperty from 'components/edit-property';
import Error from 'components/error';
import Favorites from 'components/favorites';
import Home from 'components/home';
import Mortgage from 'components/mortgage-calculator';
import MyAnnouncements from 'components/my-announcements';
import Notifications from 'components/notifications';
import Profile from 'components/profile';
import PropertyDetails from 'components/property-details';
import Salary from 'components/salary-calculator';
import Card from 'components/section-components/card';
import SellByUserOrAgent from 'components/section-components/sell-by-user-or-agent';
import CheckAgentList from 'components/select-agent-list';
import Sell from 'components/sell';
import SellByType from 'components/sell-by-type';
import SingleAgent from 'components/single-agent';
import Test from 'components/test';
import TextPage from 'components/text-page';
import User from 'components/user';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { store } from 'reducers';
import { setModal } from 'reducers/modalsReducer';

function Root() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const currentUser = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    dispatch(auth());
    if (isAuth && !currentUser.phone_number_verified_at) {
      dispatch(setModal('phone_number_verification'));
    }
  }, [dispatch, currentUser.phone_number_verified_at, isAuth]);

  const isAuthenticated = () => {
    try {
      const token = localStorage.getItem('token');

      return !!token;
    } catch (error) {
      return false;
    }
  };

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <PrivateRoute exact path="/sell-by-type/:category" component={SellByType} />
          <PrivateRoute exact path="/sell" component={Sell} />
          <PrivateRoute exact path="/sell-by-user-or-agent/:category/:type" component={SellByUserOrAgent} />
          <Route exact path="/rent" component={Sell} />
          <Route exact path="/from-construction/:current_page" component={Construction} />
          <Route exact path="/property-details/:id" component={PropertyDetails} />
          <Route exact path="/construction-details-test/:id" component={ConstructionDetails} />
          <Route exact path="/construction-details/:id" component={ConstructionDetailsTest} />
          <Route exact path="/agents-list" component={AgentsList} />
          <Route exact path="/mortgage-calculator" component={Mortgage} />
          <Route exact path="/salary-calculator" component={Salary} />
          <PrivateRoute exact path="/select-agent" component={CheckAgentList} />
          <Route exact path="/agent/:id" component={SingleAgent} />
          <Route exact path="/error" component={Error} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/announcements" component={Announcements} />
          <PrivateRoute exact path="/add-property/:category/:type/:broker?" component={AddNew} />
          <PrivateRoute exact path="/choose-agent/:category/:type" component={ChooseBroker} />
          <PrivateRoute exact path="/edit-property/:id" component={EditProperty} />
          <Route exact path="/agencies-list" component={AgenciesList} />
          <Route exact path="/agency/:id" component={AgencyPage} />
          <Route exact path="/constructor-agency/:id" component={ConstAgency} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <Route exact path="/my-announcements" component={MyAnnouncements} />
          <PrivateRoute exact path="/favorites" component={Favorites} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/bank-request" component={BankRequest} />
          <Route exact path="/page/:slug" component={TextPage} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/coming_soon" component={ComingSoon} />
          <Route exact path="/reset_password/:token/:email" component={Home} />
          <Route path="/user/:id" component={User} />
          <Route path="/card-test" component={Card} />
          <Route component={Error} />
        </Switch>
      </div>
    </Router>
  );
}

export default Root;

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('sq')
);
